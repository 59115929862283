import { Container } from "./styles";
import emailIcon from "../../assets/email-icon.svg";
// import phoneIcon from "../../assets/phone-icon.svg";
import { Form } from "../Form/Form";

export function Contact() {
  return (
    <Container id='contact'>
      <header>
        <h2>Contact me</h2>
        <p>Drop me a message</p>
      </header>
      <div className='contacts'>
        <div>
          <img src={emailIcon} alt='Email' />
          <a href='mailto:info@eliechaaban.com'>info@eliechaaban.com</a>
        </div>
        {/* <div>
          <img src={phoneIcon} alt='Phone' />
          <a href='tel:+'></a>
        </div> */}
      </div>
      <Form></Form>
    </Container>
  );
}
