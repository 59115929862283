import { Container } from "./styles";

import profileImage from "../../assets/profile-image.jpg";
import cssIcon from "../../assets/css-icon.svg";
import htmlIcon from "../../assets/html-icon.svg";
import jsIcon from "../../assets/js-icon.svg";
import nodeIcon from "../../assets/node-icon.svg";
import reactIcon from "../../assets/react-icon.svg";
import dotNetCoreIcon from "../../assets/dot-net-core-icon.svg";
import sqlIcon from "../../assets/microsoft-sql-server-icon.svg";
import ScrollAnimation from "react-animate-on-scroll";

export function About() {
  return (
    <Container id='about'>
      <div className='about-text'>
        <ScrollAnimation animateIn='fadeInLeft'>
          <h2>about me</h2>
        </ScrollAnimation>
        <ScrollAnimation animateIn='fadeInLeft' delay={0.2 * 1000}>
          <p>
            First and foremost, I love writing code. Ever since writing my first
            program in C++ and manipulating it to produce a desired output, I
            have been obsessed with the idea of using software to solve
            practical problems. Software engineering is a never-ending puzzle
            that I am passionately engaged in solving. I believe in the power of
            programming to transform and improve the lives of people around the
            world.
          </p>
        </ScrollAnimation>
        <ScrollAnimation
          animateIn='fadeInLeft'
          delay={0.4 * 1000}
          style={{ marginTop: "2rem", marginBottom: "2rem" }}
        >
          <p>
            Even when I leave the office, I tend to continue writing code at the
            house. Most recently I have been working on multiple websites and
            mobile applications as a freelancer. This is what keeps me up at
            night, a never ending thirst to create beautiful, powerful things
            and share them with the world.
          </p>
        </ScrollAnimation>
        <ScrollAnimation
          animateIn='fadeInLeft'
          delay={0.5 * 1000}
          style={{ marginTop: "2rem", marginBottom: "2rem" }}
        >
          <p>
            I have worked For <b>BusinessPack Software Solutions </b>for 6 years
            gaining lots of experience as a Software Engineer in the ERP
            industry.
          </p>
          <br />
          <p>
            Now I am working since July 2021 for <b>Zain Tech </b>, Zain Group's
            regional Digital & ICT arm, driving transformation of enterprises &
            governments in the MENA region. I am mainly focused on the
            development of the Command & Insights platform, an innovation
            enabler that helps city and district stakeholders convert their data
            into real-time and actionable insights in order to control, optimise
            operations and reduce costs and risks.
          </p>
        </ScrollAnimation>
        <ScrollAnimation animateIn='fadeInLeft' delay={0.6 * 1000}>
          <p>
            So, now, in industry, this passion remains with me. There's
            certainly yet more to learn, yet more problems to solve, and yet
            more to build. And for this, I am very grateful.
          </p>
        </ScrollAnimation>

        <ScrollAnimation animateIn='fadeInLeft' delay={0.7 * 1000}>
          <h3>Here are my main skills:</h3>
        </ScrollAnimation>
        <div className='hard-skills'>
          <div className='hability'>
            <ScrollAnimation animateIn='fadeInUp' delay={0.1 * 1000}>
              <img src={reactIcon} alt='React' />
            </ScrollAnimation>
          </div>
          <div className='hability'>
            <ScrollAnimation animateIn='fadeInUp' delay={0.2 * 1000}>
              <img src={jsIcon} alt='JavaScript' />
            </ScrollAnimation>
          </div>
          <div className='hability'>
            <ScrollAnimation animateIn='fadeInUp' delay={0.3 * 1000}>
              <img src={htmlIcon} alt='Html' />
            </ScrollAnimation>
          </div>
          <div className='hability'>
            <ScrollAnimation animateIn='fadeInUp' delay={0.4 * 1000}>
              <img src={cssIcon} alt='Css' />
            </ScrollAnimation>
          </div>
          <div className='hability'>
            <ScrollAnimation animateIn='fadeInUp' delay={0.5 * 1000}>
              <img src={nodeIcon} alt='Node' />
            </ScrollAnimation>
          </div>
          <div className='hability'>
            <ScrollAnimation animateIn='fadeInUp' delay={0.6 * 1000}>
              <img src={dotNetCoreIcon} alt='Dot Net Core' />
            </ScrollAnimation>
          </div>
          <div className='hability'>
            <ScrollAnimation animateIn='fadeInUp' delay={0.7 * 1000}>
              <img src={sqlIcon} alt='SQL Server' />
            </ScrollAnimation>
          </div>
        </div>
      </div>
    </Container>
  );
}
