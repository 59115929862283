import { BrowserRouter } from "react-router-dom";
import { Container } from "./styles";
import ScrollAnimation from "react-animate-on-scroll";
import Illustration from "../../assets/illustration.svg";
import { NavHashLink } from "react-router-hash-link";
export function Hero() {
  return (
    <Container id='home'>
      <div className='hero-text'>
        <ScrollAnimation animateIn='fadeInUp'>
          <p>Hello 👋, I am</p>
        </ScrollAnimation>
        <ScrollAnimation animateIn='fadeInUp' delay={0.2 * 1000}>
          <h1>Elie Chaaban</h1>
        </ScrollAnimation>
        <ScrollAnimation animateIn='fadeInUp' delay={0.4 * 1000}>
          <h3>Full Stack Software Engineer</h3>
        </ScrollAnimation>
        <ScrollAnimation animateIn='fadeInUp' delay={0.6 * 1000}>
          <p className='small-resume'>
            Obsessed with the idea of using software to solve practical
            problems.
          </p>
        </ScrollAnimation>

        <ScrollAnimation animateIn='fadeInUp' delay={0.8 * 1000}>
          <BrowserRouter>
            <NavHashLink smooth to='#contact' className='button'>
              Contact Me
            </NavHashLink>
          </BrowserRouter>
        </ScrollAnimation>
      </div>
      <div className='hero-image'>
        <ScrollAnimation animateIn='fadeInRight' delay={1 * 1000}>
          <img src={Illustration} alt='Ilustração' />
        </ScrollAnimation>
      </div>
    </Container>
  );
}
