import { Container } from "./styles";

import reactIcon from "../../assets/react-icon.svg";
import linkedinIcon from "../../assets/linkedin.png";
import githubIcon from "../../assets/github.png";
import instagramIcon from "../../assets/instagram.png";

export function Footer() {
  return (
    <Container className='footer'>
      <a href='/' className='logo'>
        <span>E</span>
        <span>Chaaban</span>
      </a>
      <div>
        <p>
          This website was made with <img src={reactIcon} alt='React' /> and a
          lot of
          <span>❤️</span>
        </p>
      </div>

      <div className='social-media'>
        <a
          href='https://www.linkedin.com/in/eliechaaban/'
          target='_blank'
          rel='noreferrer'
        >
          <img src={linkedinIcon} alt='Linkedin' />
        </a>

        <a
          href='https://github.com/elie-chaaban'
          target='_blank'
          rel='noreferrer'
        >
          <img src={githubIcon} alt='GitHub' />
        </a>

        <a
          href='https://www.instagram.com/eliechaaban/'
          target='_blank'
          rel='noreferrer'
        >
          <img src={instagramIcon} alt='Instagram' />
        </a>
      </div>
    </Container>
  );
}
